import React, { useRef } from "react"
import styled from "styled-components/macro"
import Logo from "./logo"
import { breakpoint as bp, Btn } from "./styles"

const menuItems = [
  {
    name: "About us",
    children: [
      {
        name: "Who we are",
        link: "https://planswell.com/about-us/",
      },
      {
        name: "What we do",
        link: "https://planswell.com/what-we-do/",
      },
      {
        name: "Why planswell",
        link: "https://planswell.com/about-us/#why-planswell",
      },
    ],
  },
  {
    name: "Services",
    children: [
      {
        name: "Financial planning",
        link: "https://planswell.com/financial-planning/",
      },
      {
        name: "Mortgages",
        link: "https://planswell.com/mortgages/",
      },
      {
        name: "Investments",
        link: "https://planswell.com/investments/",
      },
      {
        name: "Insurance",
        link: "https://planswell.com/insurance-protection/",
      },
    ],
  },
  {
    name: "Tools",
    children: [
      {
        name: "Child Affordability Calculator",
        link: "https://planswell.com/child-affordability-calculator/",
      },
      {
        name: "Insurance + Investment Calculator",
        link: "https://planswell.com/insurance-investment-calculator/",
      },
      {
        name: "Monthly Budget Calculator",
        link: "https://planswell.com/monthly-budget-calculator/",
      },
    ],
  },
  {
    name: "Resources",
    children: [
      {
        name: "Our assumptions",
        link: "https://planswell.com/our-assumptions/",
      },
      {
        name: "Financial planning 101",
        link: "https://planswell.com/financial-planning-101/",
      },
    ],
  },
  {
    name: "Blog",
  },
  {
    name: "Log in",
  },
]

export default function HeaderComponent() {
  const navRef = useRef(null)

  function handleMenu() {
    navRef.current.classList.toggle("change")
    document.querySelector(".navigation").classList.toggle("show")
  }

  return (
    <Header>
      <Logo />

      <Nav className="navigation">
        {menuItems.map(function renderMenuItems(item, index) {
          return (
            <NavItem key={index}>
              <NavLink className={item.children && "hasChildren"}>
                {item.name}
              </NavLink>

              {item.children && (
                <NavDropdown>
                  {item.children.map((child, i) => (
                    <span key={i}>
                      <a href={child.link}>{child.name}</a>
                    </span>
                  ))}
                </NavDropdown>
              )}
            </NavItem>
          )
        })}
        <NavItem>
          <NavBtn href="https://my.planswell.ca/discovery/ORG-Home?_ga=2.157437757.646163855.1606068670-1867592009.1599084796">
            Start Now
          </NavBtn>
        </NavItem>
      </Nav>

      <Hamburger ref={el => (navRef.current = el)} onClick={handleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </Hamburger>
    </Header>
  )
}

const Header = styled.header`
  background-color: var(--color-steel-white);
  min-height: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2023;
  transition: 2s ease;
`

const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media only screen and (max-width: ${bp.medium}) {
    display: inline-block;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 32px;
    height: 4px;
    background-color: var(--color-green-medium);
    margin: 5px 0;
    transition: 0.4s;
    border-radius: 2px;
  }

  &.change .bar1 {
    transform: rotate(-45deg) translate(-5px, 5px);
  }

  &.change .bar2 {
    opacity: 0;
  }

  &.change .bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
  }
`

const Nav = styled.nav`
  display: flex;
  align-self: stretch;
  order: 3;

  @media only screen and (max-width: ${bp.medium}) {
    display: none;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    &.show {
      display: flex;
    }
  }
`

const NavDropdown = styled.div`
  position: absolute;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;

  span {
    display: block;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    &:not(:last-child) {
      border-bottom: 1px solid #eef1f2;
    }

    a {
      margin: 0 30px;
      word-wrap: nowrap;
      color: var(--color-steel-dark);
      transition: all 0.3s ease;
      &:hover {
        color: var(--color-green-medium);
      }
    }
  }

  @media only screen and (max-width: ${bp.medium}) {
    position: relative;
    display: none;
    top: 0;
    width: 100vw;
    background-color: #eef1f2;
    z-index: 1;
  }

  &::before {
    position: absolute;
    content: "";
    display: block;
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media only screen and (max-width: ${bp.medium}) {
      display: none;
    }
  }
`

const NavItem = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-steel-dark);
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${bp.medium}) {
    margin: 5px;
    flex-direction: column;
  }

  &:nth-of-type(even) {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }

  &:hover ${NavDropdown} {
    opacity: 1;
    visibility: visible;

    @media only screen and (max-width: ${bp.medium}) {
      display: block;
    }
  }

  @media only screen and (max-width: ${bp.medium}) {
    &:focus ${NavDropdown} {
      display: block;
    }
  }
`

const NavBtn = styled.a`
  ${Btn}
`

const NavLink = styled.span`
  display: flex;
  align-items: center;
  height: 50%;
  cursor: pointer;
  position: relative;
  &.hasChildren::after {
    font-size: 14px;
    margin-left: 5px;
    margin-top: 3px;
    display: inline-block;
    content: " ";
    background-size: 10px;
    height: 14px;
    width: 12px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://planswell.com/wp-content/themes/planswell/includes/images/arrow-dark.svg");
    transition: 0.4s;
  }
`
