/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components/macro"

import Header from "./header"
import Footer from "./footer"

const GlobalStyle = createGlobalStyle`
:root {
	--color-green-light: #e7fef2;
	--color-green-medium: #16a55a;
	--color-green-dark: #05823f;

	--color-leaf-light: #f8f7f6;
	--color-leaf-medium: #eaeae9;
	--color-leaf-dark: #e7e7de;

	--color-steel-white: #ffffff;
	--color-steel-light: #a5aeb0;
	--color-steel-medium: #6d7475;
	--color-steel-dark: #3c4142;
	--color-steel-extra-light: #eef1f2;

	--line: 1px solid var(--color-steel-light);
	--line-primary: 4px solid var(--color-green-medium);
	--page-container: 860px;
}

$star-rating: (
	star-empty: url(../images/star-empty.svg),
	star-full: url(../images/star-full.svg),
	star-half: url(../images/star-half.svg),
);

@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Lato Regular"), local("Lato-Regular"),
		url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXiWtFCc.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local("Lato Bold"), local("Lato-Bold"),
		url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

* {
	// margin: 0;
	// padding: 0;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	box-sizing: border-box !important;
	font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

	@media only screen and (max-width: $bp-large) {
		font-size: 50%;
	}
}

body {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 1.6;
	color: var(--color-steel-dark);
	background-color: var(--color-leaf-light);
	min-height: 100vh;
	overflow-x: hidden;
	margin-top: 6.4rem;

	@media only screen and (min-width: $bp-largest) {
		// line-height: 3.2;
	}
}

a {
	text-decoration: none;
}

img,
video {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
	object-position: center;

	&.img-contain {
		object-fit: contain;
	}
}

ul {
	list-style: none;
}

.heading-large {
	font-size: 4.4rem;
}

.heading-medium {
	font-size: 30px;
}

.paragraph {
	font-size: 16px;
	line-height: 19px;
}

.text-white {
	color: var(--color-steel-white);
}

.text-green-medium {
	color: var(--color-green-medium) !important;
}

.position-relative {
	position: relative !important;
}
.bg-white {
	background-color: var(--color-steel-white);
}

.overflow-hidden {
	overflow: hidden;
}


////////////////////////////////////////
// UTILITIES

.u-position-relative {
	position: relative;
}

.u-margin {
	&-left-auto {
		margin-left: auto;
	}

	&-right-auto {
		margin-right: auto;
	}

	&-right-1 {
		margin-right: 1rem;
	}

	&-top-auto {
		margin-top: auto;
	}

	&-top-1 {
		margin-top: 1rem;
	}

	&-top-2 {
		margin-top: 2rem;
	}

	&-top-6 {
		margin-top: 6rem;
	}

	&-top-11 {
		margin-top: 11rem;
	}

	&-bottom-auto {
		margin-bottom: auto;
	}

	&-bottom-2 {
		margin-bottom: 2rem;
	}

	&-bottom-3 {
		margin-bottom: 3rem;
	}
}

.u-padding {
	&-top-3 {
		padding-top: 3rem;
	}

	&-top-5 {
		padding-top: 5rem;
	}

	&-top-15 {
		padding-top: 15rem;
	}

	&-bottom-0 {
		padding-bottom: 0rem;
	}

	&-bottom-5 {
		padding-bottom: 5rem;
	}
}

.hide {
	@media only screen and (max-width: $bp-small) {
		&--sm {
			display: none;
		}
	}
	
	@media only screen and (max-width: $bp-smallest) {
		&--xs {
			display: none;
		}
	}
}

.has-oval {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		width: 0.4rem;
		height: 0.4rem;
		background-color: var(--color-steel-white);
		right: -0.25rem;
		top: 50%;
		border-radius: 50%;
	}
}





.flex {
	display: flex;

	&--column {
		flex-direction: column;
	}

	&--wrap {
		flex-wrap: wrap;
	}

	&--gutter {
		margin: 0 -1rem;

		& > * {
			padding: 0 1rem;
		}

		&-sm {
			margin: 0 -0.5rem;

			& > * {
				padding: 0 0.5rem;
			}
		}

		&-lg {
			margin: 0 -5rem;

			& > * {
				padding: 0 5rem;
			}
		}
	}
}

.align-items-center {
	align-items: center;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-end {
	justify-content: flex-end;
}

pw-row {
	width: auto;
	margin: -8px;
}

pw-col {
	padding: 8px;
}

p-card {
	height: 100%;
}

.ReactModal__Overlay {
	z-index: 9999;

	background-color: rgba(4, 4, 4,  .58) !important;
}

.ReactModal__Content {
	display: flex;
	justify-content: center;
	border: 0 !important;
	background: none !important;
	pointer-events: none;
	width: 100%;
	left: 0 !important;
	right: 0 !important;

	pw-row {
		align-items: center;
		justify-content: space-around;
		width: 100%;
    margin: auto;
	}

	pw-col {
		pointer-events: all;
	}
}

`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <FullHeight>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      {children}

      <Footer />
    </FullHeight>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

export default Layout
