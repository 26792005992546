import styled, { css } from "styled-components/macro"

export const breakpoint = {
  desktop: "160em", // 2560px
  largest: "90em", // 1440px
  large: "64em", // 1024px
  medium: "48em", // 768px
  small: "37.5em", // 600px
  smallest: "31.25em", // 500px
}

const Shared = css`
  width: 100%;
  margin: 0 auto;
  padding-left: 64px;
  padding-right: 64px;

  // @media only screen and (max-width: ${breakpoint.largest}) {
  //   padding-left: 120px;
  //   padding-right: 120px;
  // }

  // @media only screen and (max-width: ${breakpoint.large}) {
  //   padding-left: 86px;
  //   padding-right: 86px;
  // }

  @media only screen and (max-width: ${breakpoint.medium}) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @media only screen and (max-width: ${breakpoint.small}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const Container = styled.div`
  max-width: 118.5rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

export const ContainerFluid = styled.div`
  ${Shared}
`

export const Main = styled.main`
  ${Shared}
`

export const Header = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem;
  max-width: 674px;
`

export const HeaderTitle = styled.p`
  font-weight: 700;
  margin-bottom: 32px;
`

export const HeaderMain = styled.h3`
  font-size: 4.5rem;
  margin: 2rem 0;
`

export const HeaderSub = styled.p`
  font-size: 18px;
  margin: 0;
  @media only screen and (max-width: ${breakpoint.small}) {
    font-size: 16px;
  }
`

export const Textfield = styled.div`
  margin-bottom: 3rem;
  position: relative;
  width: 100%;
`

export const TextfieldWrapper = styled.div`
  position: relative;

  &.hasAfter {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 31px;
      top: 0px;
      background: var(--color-steel-white);
      z-index: 0;
    }
  }
`

export const TextfieldLabel = css`
  position: absolute;
  bottom: 0;
  left: 0;
  top: calc(50% - 1rem);
  font-weight: 400;
  font-size: 1.6rem;
  transition: all 0.3s;
  width: 100%;
  pointer-events: none;
  cursor: text;
  z-index: 2;

  &.textfield__label--link {
    &,
    &:link {
      color: var(--color-green-medium);
      text-decoration: underline;
    }

    &:visited {
      color: currentColor;
    }
  }

  &.textfield__label--check {
    position: relative;
    font-size: 1.6rem;
    padding-left: 2.5rem;
    cursor: pointer;
    pointer-events: all;
    &::before {
      position: absolute;
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      left: 0;
      font-size: 1.3rem;
      line-height: 1;
      text-align: center;
      color: var(--color-steel-white);
      bottom: 0.1rem;
      border: var(--line);

      border-radius: 5%;
      transition: all 0.3s;
      background-position: center center;
      background-size: 1rem;
      background-repeat: no-repeat;
    }
  }

  &.static {
    // transform: translateY(-3.5rem);
    top: 0;
    font-size: 1.2rem;
  }
`

export const TextInput = css`
  background: red;
  display: block;
  width: 100%;
  padding-top: 0.85rem;
  padding-bottom: 0;
  font-size: 1.6rem;
  font-weight: 400;
  min-height: 6.5rem;
  line-height: -1;
  outline: none;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;

  &:placeholder-shown ~ .textfield__label,
  &:focus ~ .textfield__label,
  &:valid ~ .textfield__label {
    top: 0;
    font-size: 1.2rem;
  }

  &:focus ~ .textfield__filler,
  &:valid ~ .textfield__filler {
    &::after {
      width: 100%;
    }
  }
`

export const Btn = css`
  position: relative;
  -webkit-appearance: none;
  outline: none;
  padding: 3px 10px;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-steel-white);
  border: 1px solid transparent;
  border-radius: 1.6rem;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 1003;
  width: 100%;

  background: var(--color-green-medium);
`
