import React from "react"
import styled from "styled-components/macro"
import { ContainerFluid, breakpoint as bp } from "./styles"
import Facebook from "../icons/facebook.svg"
import Twitter from "../icons/twitter.svg"
import Instagram from "../icons/instagram.svg"
import Linkedin from "../icons/linkedin.svg"
import Youtube from "../icons/youtube.svg"
import Mail from "../icons/mail.svg"
import Phone from "../icons/phone.svg"
import Wave from "../icons/wave.svg"

const aboutData = [
  {
    title: "Who we are",
    link: "https://planswell.com/about-us/",
  },
  {
    title: "What we do",
    link: "https://planswell.com/what-we-do/",
  },
  {
    title: "Why Planswell",
    link: "https://planswell.com/about-us/#why-planswell",
  },
  {
    title: "Planswell Reviews",
    link: "/",
  },
  {
    title: "Press",
    link: "https://planswell.com/press/",
  },
  {
    title: "Privacy Policy",
    link: "https://planswell.com/privacy-policy/",
  },
  {
    title: "Terms of Service",
    link: "https://planswell.com/terms-of-service/",
  },
]

const resourcesData = [
  {
    title: "Blog",
    link: "https://planswell.com/blog/",
  },
  {
    title: "Financial Planning 101",
    link: "https://planswell.com/financial-planning-101/",
  },
  {
    title: "Our Assumptions",
    link: "https://planswell.com/our-assumptions/",
  },
  {
    title: "Sitemap",
    link: "https://planswell.com/sitemap/",
  },
  {
    title: "Workplace Violence, Harassment and Discrimination Policy",
    link:
      "https://planswell.com/wp-content/uploads/2020/07/Planswell-Workplace-Violence-Harassment-and-Discrimination-Policy.pdf",
  },
  {
    title: "Code of Conduct",
    link:
      "https://planswell.com/wp-content/uploads/2020/11/Planswell-Code-of-Conduct-June-2020-3.pdf",
  },
]

const toolsData = [
  {
    title: "Child Addordability Calculator",
    link: "https://planswell.com/child-affordability-calculator/",
  },
  {
    title: "Insurance + Investments Calculator",
    link: "https://planswell.com/what-we-do/",
  },
  {
    title: "Monthly Budget Calculator",
    link: "https://planswell.com/monthly-budget-calculator/",
  },
]

const servicesData = [
  {
    title: "Financial Planning",
    link: "https://planswell.com/financial-planning/",
  },
  {
    title: "Mortgages",
    link: "https://planswell.com/mortgages/",
  },
  {
    title: "Investments",
    link: "https://planswell.com/investments/",
  },
  {
    title: "Insurance",
    link: "https://planswell.com/insurance-protection/",
  },
  {
    title: "For Advisors",
    link: "https://planswell.com/advisor/",
  },
]

export default function FooterComponent() {
  return (
    <Footer>
      <div className="wave">
        <Wave />
      </div>
      <FooterTop>
        <ContainerFluid>
          <pw-row>
            <pw-col xl="2" lg="3" nm="2" sm="12">
              <pw-heading size="small" color="white">
                About
              </pw-heading>
              <Ul>
                {aboutData.map(function renderAbout(data, index) {
                  return (
                    <li key={index}>
                      <Link href={data.link}>{data.title}</Link>
                    </li>
                  )
                })}
              </Ul>
            </pw-col>
            <pw-col xl="2" lg="3" nm="2" sm="12">
              <pw-heading size="small" color="white">
                Resources
              </pw-heading>

              <Ul>
                {resourcesData.map(function renderAbout(data, index) {
                  return (
                    <li key={index}>
                      <Link href={data.link}>{data.title}</Link>
                    </li>
                  )
                })}
              </Ul>
            </pw-col>
            <pw-col xl="2" lg="3" nm="2" sm="12">
              <pw-heading size="small" color="white">
                Tools
              </pw-heading>

              <Ul>
                {toolsData.map(function renderAbout(data, index) {
                  return (
                    <li key={index}>
                      <Link href={data.link}>{data.title}</Link>
                    </li>
                  )
                })}
              </Ul>
            </pw-col>
            <pw-col xl="2" lg="3" nm="2" sm="12">
              <pw-heading size="small" color="white">
                Services
              </pw-heading>

              <Ul>
                {servicesData.map(function renderAbout(data, index) {
                  return (
                    <li key={index}>
                      <Link href={data.link}>{data.title}</Link>
                    </li>
                  )
                })}
              </Ul>
            </pw-col>
            <pw-col
              xl="3"
              lg="12"
              nm="3"
              sm="12"
              style={{ marginLeft: "auto" }}
            >
              <pw-heading size="small" color="white">
                Contact Us
              </pw-heading>

              <pw-row class="contact-row">
                <pw-col
                  nm="12"
                  lg="4"
                  sm="12"
                  class="order-lg-3"
                  style={{ marginLeft: "auto" }}
                >
                  <Ul className="socials">
                    <li>
                      <Link href="https://www.facebook.com/planswell/">
                        <Facebook />
                      </Link>
                    </li>
                    <li>
                      <Link href="https://twitter.com/getplanswell?lang=en">
                        <Twitter />
                      </Link>
                    </li>
                    <li>
                      <Link href="https://www.instagram.com/getplanswell/">
                        <Instagram />
                      </Link>
                    </li>
                    <li>
                      <Link href="https://ca.linkedin.com/company/planswell">
                        <Linkedin />
                      </Link>
                    </li>
                    <li>
                      <Link href="https://www.youtube.com/channel/UCkqoF6P3vXKnfdsPUgkog4g">
                        <Youtube />
                      </Link>
                    </li>
                  </Ul>
                </pw-col>

                <pw-col nm="12" lg="3" md="4" sm="6">
                  <Hotlink>
                    <Mail />
                    <Link href="mailto:hello@planswell.com">
                      hello@planswell.com
                    </Link>
                  </Hotlink>
                </pw-col>

                <pw-col nm="12" lg="3" md="4" sm="6">
                  <Hotlink>
                    <Phone />
                    <Link href="mailto:hello@planswell.com">
                      1-844-218-7526
                    </Link>
                  </Hotlink>
                </pw-col>
              </pw-row>
            </pw-col>
          </pw-row>
        </ContainerFluid>
      </FooterTop>

      <FooterBottom className="footer__bottom">
        <ContainerFluid>
          <pw-row>
            <pw-col>
              <p className="paragraph">Planswell Corp. Copyright &copy; 2020</p>
            </pw-col>
          </pw-row>
        </ContainerFluid>
      </FooterBottom>
    </Footer>
  )
}

const Footer = styled.footer`
  color: var(--color-steel-white);

  .wave {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -3px;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  @media only screen and (max-width: ${bp.large}) {
    .order-lg-3 {
      order: 3;
    }
  }

  .contact-row {
    align-items: center;
    @media only screen and (max-width: ${bp.small}) {
      margin-top: 10px;

      justify-content: center;
    }
  }
`

const Ul = styled.ul`
  padding: 0;

  &.socials {
    display: flex;
    margin: 0 -10px;

    @media only screen and (max-width: ${bp.large}) {
      justify-content: flex-end;
    }

    @media only screen and (max-width: ${bp.small}) {
      justify-content: center;
    }

    li {
      padding: 0 10px;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: flex-end;

      a {
        display: block;
      }

      svg {
        width: 100%;
        height: 100%;
        fill: var(--color-steel-white);
      }
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

const Link = styled.a`
  color: var(--color-steel-white);
  opacity: 0.5;
  transition: all 0.3s ease;
  font-size: 16px;

  &:hover {
    opacity: 1;
  }
`

const Hotlink = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${bp.small}) {
    flex-direction: column;
  }

  svg {
    fill: var(--color-steel-white);
    height: 10px;
    width: 14px;
    margin-right: 10px;

    @media only screen and (max-width: ${bp.small}) {
      margin: 20px;
      height: 15px;
      width: 19px;
    }
  }
`

const FooterTop = styled.div`
  background: var(--color-green-medium);
  border-radius: 0 0 2.5rem 2.5rem;
  padding-top: 2rem;
  padding-bottom: 6rem;
`

const FooterBottom = styled.div`
  position: relative;
  background: var(--color-green-dark);
  padding: 1.8rem 0;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    min-height: 4rem;
    top: -3rem;
    background: var(--color-green-dark);
    z-index: -1;
  }

  pw-row {
    justify-content: center;
  }

  @media only screen and (max-width: ${bp.small}) {
    text-align: center;
  }
`
